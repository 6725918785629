import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styles from './Footer.module.scss';
import LogoWhite from '../../assets/images/footer/itu-logo-white.png';
import Icon1 from '../../assets/images/footer/1.svg';
import Icon2 from '../../assets/images/footer/2.svg';
import Icon3 from '../../assets/images/footer/3.svg';
import Icon4 from '../../assets/images/footer/4.svg';
import Icon5 from '../../assets/images/footer/5.svg';
import Icon6 from '../../assets/images/footer/6.svg';
import Icon7 from '../../assets/images/footer/7.svg';
import Icon8 from '../../assets/images/footer/8.svg';
import Icon9 from '../../assets/images/footer/9.svg';
import Icon10 from '../../assets/images/footer/10.svg';
import Icon11 from '../../assets/images/footer/11.svg';

const Footer = () => {
  const { t } = useTranslation();
  const {
    REACT_APP_FACEBOOK,
    REACT_APP_CONTACTS,
    REACT_APP_FLICKR,
    REACT_APP_INSTAGRAM,
    REACT_APP_LINKEDIN,
    REACT_APP_PODCAST,
    REACT_APP_PRIVACY,
    REACT_APP_REPORT,
    REACT_APP_SOUNDCLOUD,
    REACT_APP_SPOTIFY,
    REACT_APP_SPEAKER,
    REACT_APP_TIKTOK,
    REACT_APP_TWITTER,
    REACT_APP_YOUTUBE,
  } = process.env;

  const getIconTitle = (title) => {
    const trTitle = t(`footer.sites.${title}`);
    return `${t('footer.visit')} ${t('footer.itu')} ${trTitle}`;
  };
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerFirst}>
          <span className={styles.socialTitle}>{t('footer.social')}</span>
          <div className={styles.footerSocialIcons}>
            <a target="_blank" rel="noreferrer" href={REACT_APP_FACEBOOK}>
              <img src={Icon1} title={getIconTitle('facebook')} alt={getIconTitle('facebook')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_INSTAGRAM}>
              <img src={Icon2} title={getIconTitle('instagram')} alt={getIconTitle('instagram')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_TWITTER}>
              <img src={Icon3} title={getIconTitle('x')} alt={getIconTitle('x')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_LINKEDIN}>
              <img src={Icon6} title={getIconTitle('linkedin')} alt={getIconTitle('linkedin')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_TIKTOK}>
              <img src={Icon11} title={getIconTitle('tiktok')} alt={getIconTitle('tiktok')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_YOUTUBE}>
              <img src={Icon4} title={getIconTitle('youtube')} alt={getIconTitle('youtube')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_FLICKR}>
              <img src={Icon5} title={getIconTitle('flickr')} alt={getIconTitle('flickr')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_SPOTIFY}>
              <img src={Icon7} title={getIconTitle('spotify')} alt={getIconTitle('spotify')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_SOUNDCLOUD}>
              <img src={Icon8} title={getIconTitle('soundcloud')} alt={getIconTitle('soundcloud')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_PODCAST}>
              <img src={Icon9} title={getIconTitle('podcasts')} alt={getIconTitle('podcasts')} />
            </a>
            <a target="_blank" rel="noreferrer" href={REACT_APP_SPEAKER}>
              <img src={Icon10} title={getIconTitle('spreaker')} alt={getIconTitle('spreaker')} />
            </a>
          </div>
        </div>
        <div className={styles.footerHr} />
        <div className={styles.footerSecond}>
          <div className={styles.footerSecondFlex}>
            <div className={styles.footerLogo}>
              <Link to="/" className={styles.logoLink}>
                <img src={LogoWhite} alt={t('logo')} />
              </Link>
              <div className={clsx('nowrap', styles.footerCopy)}>
                &copy; {new Date().getFullYear()} {t('footer.copyright')}
              </div>
            </div>
            <div className={styles.footerMenu}>
              <a className={clsx('nowrap')} target="_blank" rel="noreferrer" href={REACT_APP_REPORT}>
                {t('footer.reportText')}
              </a>
              <a className={clsx('nowrap')} target="_blank" rel="noreferrer" href={REACT_APP_PRIVACY}>
                {t('footer.privacyText')}
              </a>
              <a className={clsx('nowrap')} href={REACT_APP_CONTACTS}>
                {t('footer.contactText')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
